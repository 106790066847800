import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { CMCURL } from 'constants/constant.app'

export const onboardingApi = createApi({
    reducerPath: 'onboardingApi',
    tagTypes: ['get', "update", "add"],
    baseQuery: fetchBaseQuery({
        baseUrl: CMCURL,
        //  prepareHeaders: (headers, { getState }) => {
        //     const token = getState().auth.token
        //     if (token) {
        //         headers.set('authorization', `Bearer ${token}`)
        //     }
        //     return headers
        // },
    }),

    endpoints: (builder) => ({

        getOnboarding: builder.query({
            query: () => "/onboards",
            providesTags: ['get']
        }),
        getNotices: builder.query({
            query: () => "/guides",
            providesTags: ['get']
        }),
        getMoreInfo: builder.query({
            query: () => "/onboarding-details",
            providesTags: ['get']
        }),
        getFAQS: builder.query({
            query: () => "/faqs",
            //providesTags: ['get']
        }),
        getTermsAndConditions: builder.query({
            query: () => "/termsandcondition",
            //providesTags: ['get']
        }),


        getPrivacyPolicy: builder.query({
            query: () => "/privacypolicy",
            //providesTags: ['get']
        }),

    }),
})

export const {
    useGetOnboardingQuery,
    useGetNoticesQuery,
    useGetMoreInfoQuery,
    useGetFAQSQuery,
    useGetTermsAndConditionsQuery,
    useGetPrivacyPolicyQuery
} = onboardingApi