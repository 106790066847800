export const SIGNIN_REQUEST = 'SIGNIN'; 
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const SIGNIN_FAIL ="SININ_FAIL";


export const SIGNOUT = 'SIGNOUT';
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS'

export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SIGNUP_FAIL ="SIGNUP_FAIL";

export const SHOW_AUTH_MESSAGE = 'SHOW_AUTH_MESSAGE'

export const SHOW_LOADING = 'SHOW_LOADING'
export const AUTH_TOKEN = 'auth_token'
export const AUTH_USER = 'auth_user'


export const HIDE_SHOW_LOADING = "HIDE_SHOW_LOADING";