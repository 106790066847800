import React, { useEffect, useState } from "react";
import { Menu, Switch, Popover, Tag } from "antd";
import { useDispatch } from "react-redux";
import { NavProfile } from "./NavProfile";
import { mode } from "redux/reducers/reducers.app";

import { Link } from "react-router-dom";


import { useGetUserDetailQuery, useUpdateThemeMutation } from "services/service.user";

const NavPanel = () => {


  const [updateMode] = useUpdateThemeMutation();
  const { data: userDetail, isSuccess } = useGetUserDetailQuery();

  const [isTest, setIsTest] = useState(true);

  const dispatch = useDispatch();


  let isAdminApproved = true;


  const handleMode = async (val) => {

    if (isAdminApproved) {

      setIsTest(val);
      await updateMode({ testMode: val });



    }


  }

  useEffect(() => {
    if (isSuccess) {
      setIsTest(userDetail?.data?.arifPayUserInfo.testMode);
      dispatch(mode(userDetail?.data?.arifPayUserInfo?.testMode));
    }
  }, [isSuccess, userDetail?.data?.arifPayUserInfo?.testMode, dispatch]);


  return (
    <>
      <div>

        {isTest ? <Tag color="" >You Are Now In Test Mode</Tag> :
          <Tag color="">You Are Now In Production Mode</Tag>}

        <Popover
          content=
          {isTest ? <div className="">
            <div>
              Please <Link to={{ pathname: '/app/settings', state: { fromTest: true } }}>activate your account </Link>  to access live data
            </div>
          </div> :
            <div>
              Change to test monde to access test data
            </div>}
        >
          <Switch
            style={{ background: isTest ? "#D4B106" : "#04D182" }}
            checkedChildren="Test" unCheckedChildren="Production" checked={isTest} onChange={handleMode} />
        </Popover>
      </div>

      <Menu mode="horizontal">


        <NavProfile />

      </Menu>

    </>
  );
}




export default NavPanel;
