import { createSlice } from '@reduxjs/toolkit'


const initialStartDate = () => {
    let date = new Date();
    let currentMonth = date.getMonth();
    date.setMonth(currentMonth - 1);
    return date.toISOString().slice(0, 10)

}
const initialEndDate = () => {
    let date = new Date(Date.now())
    return date.toISOString().slice(0, 10)

}
const initialState = {
    startDate: initialStartDate(),
    endDate: initialEndDate(),
    sortedBy: 'desc',
    orderBy: 'id',
    paymentMethod: [],
    status: 'Success'
}

const TransactionSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        setStartDate(state, action) {
            state.startDate = action.payload
        },
        setEndDate(state, action) {
            state.endDate = action.payload
        },
        setSortedBy(state, action) {
            state.sortedBy = action.payload
        },
        setOrderBy(state, action) {
            state.orderBy = action.payload
        },
        setPaymentMethod(state, action) {
            state.paymentMethod = action.payload
        },
        setStatus(state, action) {
            state.status = action.payload
        }

    },
})

export const { setEndDate, setOrderBy, setPaymentMethod, setSortedBy, setStartDate, setStatus } = TransactionSlice.actions
export default TransactionSlice.reducer
