import { useEffect, useState } from "react";
import SessionStorageService from 'helper/SessionStorageService';
import { USERDATA } from "constants/constant";
import { useGetUserDetailQuery } from "services/service.user";
export const useUserData=()=>{
    const [userData, setUserData] = useState(() => SessionStorageService.get(USERDATA) || null);
  
    const { data, isSuccess } = useGetUserDetailQuery(undefined, { refetchOnReconnect: true });
  
    useEffect(() => {
      if (isSuccess) {
        SessionStorageService.set(USERDATA, data.data);
        setUserData(data.data)
      }
    }, [data?.data, isSuccess, userData])
  
  
    return {userData}
}