import CryptoJS from 'crypto-js';

class SessionStorageService {
    static set(name, value, options = {}) {

        // Encrypt the value
        const cipher = CryptoJS.AES.encrypt(JSON.stringify(value), 'your-secret-key').toString();

        sessionStorage.setItem(name,cipher) ;
      
    }

    static get(name) {

        if(sessionStorage.getItem(name) != null){

            const bytes = CryptoJS.AES.decrypt(sessionStorage.getItem(name), 'your-secret-key');
            let decrypted = bytes.toString(CryptoJS.enc.Utf8);

            return JSON.parse(decrypted);
        }

        return null;
    }

    static delete(name, options = {}) {
        
        sessionStorage.removeItem(name,'') ;
      
    }


}

export default SessionStorageService;
