import { useEffect, useState } from 'react'
import { useGetGeneralInfoQuery } from 'services/service.kyc'
export const useGeneralInfoData = () => {
    const [companyData, setCompanyData] = useState({})
    const { data: user, isSuccess } = useGetGeneralInfoQuery()
    useEffect(() => {

        if (isSuccess) {
            setCompanyData(user.data)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess, user?.data])

    return { companyData, isSuccess }
}