import { Menu, Dropdown, Avatar } from "antd";
import { SettingOutlined, LogoutOutlined } from '@ant-design/icons';
import { logoutUser } from 'redux/actions/action.auth';
import { useDispatch } from 'react-redux'
import SideNav from "./right-side-nav/SideNav";
import { IMAGEURL } from "constants/constant.app";
import { ReDirectLocation } from "helper/redirectProvider";
import { useUserData } from "hooks/useUserData";
const menuItem = [
  {
    title: "Settings",
    icon: <SettingOutlined />,
    path: "/app/settings"
  },
]

export const NavProfile = () => {

  const { userData } = useUserData();

  const dispatch = useDispatch();



  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">

          <div className="">
            <h4 className="mt-3 ml-2">{userData?.firstname}  {userData?.lastname}</h4>
            <span className="text-muted">{userData?.email}</span>
          </div>
        </div>

      </div>
      <div className="nav-profile-body">
        <Menu>


          <Menu.Item key={"sidNave"}>
            <SideNav />
          </Menu.Item>
          {
            menuItem?.map(menu =>
              <Menu.Item key={menu.title} icon={menu.icon} onClick={() => ReDirectLocation(menu.path)}>
                <span className="font-weight-normal">{menu.title}</span>
              </Menu.Item>)
          }
          <Menu.Item key={menuItem?.length + 1} onClick={() => dispatch(logoutUser())}>
            <span >
              <LogoutOutlined />
              <span className="font-weight-normal">Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (<Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
    <Menu className="d-flex align-item-center" mode="horizontal">
      <Menu.Item key="0">
        <Avatar size={45} src={IMAGEURL + userData?.photo} />
      </Menu.Item>
    </Menu>
  </Dropdown>
  );
}



export default NavProfile;
