import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Dropdown, Layout, Menu } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined, PlusOutlined, DownOutlined } from '@ant-design/icons';
import NavPanel from './NavPanel';
import NavSearch from './NavSearch';
import { toggleCollapsedNav, onMobileNavToggle } from 'redux/actions/action.theme';
import { NAV_TYPE_TOP, SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH } from 'constants/constant.theme';
import utils from 'utils'
import { useGeneralInfoData } from "hooks/useGeneralInfoData";
const { Header } = Layout;

export const HeaderNav = props => {
  const { navCollapsed, mobileNav, navType, headerNavColor, toggleCollapsedNav, onMobileNavToggle, isMobile, currentTheme, direction } = props;
  const [searchActive, setSearchActive] = useState(false)


  const { companyData, isSuccess } = useGeneralInfoData()



  const onSearchClose = () => {
    setSearchActive(false)
  }

  const onToggle = () => {
    if (!isMobile) {
      toggleCollapsedNav(!navCollapsed)
    } else {
      onMobileNavToggle(!mobileNav)
    }
  }

  const isNavTop = navType === NAV_TYPE_TOP ? true : false
  const mode = () => {
    if (!headerNavColor) {
      return utils.getColorContrast(currentTheme === 'dark' ? '#00000' : '#ffffff')
    }
    return utils.getColorContrast(headerNavColor)
  }
  const navMode = mode()
  const getNavWidth = () => {
    if (isNavTop || isMobile) {
      return '0px'
    }
    if (navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`
    } else {
      return `${SIDE_NAV_WIDTH}px`
    }
  }

  useEffect(() => {
    if (!isMobile) {
      onSearchClose()
    }
  })



  const menuItem = [
    {
      title: "Add Business",
      icon: <PlusOutlined />,
      path: "/app/settings"
    },
  ]



  const menuItms = () => {
    return <Menu >
      {
        menuItem?.map(menu =>
          <Menu.Item key={menu.title} icon={menu.icon} >
            <span className="font-weight-normal">{menu.title}</span>
          </Menu.Item>)
      }
    </Menu>
  }

  function limitString(originalString, limit) {
    if (originalString) {
      let limitedString = originalString.substring(0, limit);
      if (originalString.length > limit) {
        limitedString += "...";
      }
      return limitedString;
    }

    return "";
  }

  return (

    <Header className={`app-header ${navMode}`} style={{ backgroundColor: headerNavColor }}>
      <div className={`app-header-wrapper ${isNavTop ? 'layout-top-nav' : ''}`}>

        {/* <Logo logoType={navMode} /> */}
        <div style={{ width: navCollapsed || isMobile ? "100px" : '250px', marginLeft: '20px' }}>
          <Dropdown overlay={menuItms} trigger={["click"]}  >
            <Button prefix={<DownOutlined />} >
              {isSuccess && limitString(companyData?.legalName, navCollapsed || isMobile ? 10 : 20)}

            </Button>

          </Dropdown>
        </div>
        <div className="nav" style={{ width: `calc(100% - ${getNavWidth()})` }}>
          <div className="nav-left">
            <ul className="ant-menu ant-menu-root ant-menu-horizontal">
              {
                isNavTop && !isMobile ?
                  null
                  : <>
                    <li className="ant-menu-item ant-menu-item-only-child" onClick={() => { onToggle() }}>
                      {navCollapsed || isMobile ? <MenuUnfoldOutlined className="nav-icon" /> : <MenuFoldOutlined className="nav-icon" />}
                    </li>

                  </>
              }
            </ul>
          </div>


          <div className="nav-right">
            <NavPanel direction={direction} />
          </div>
          <NavSearch active={searchActive} close={onSearchClose} />
        </div>
      </div>

    </Header>

  )
}

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType, headerNavColor, mobileNav, currentTheme, direction } = theme;
  return { navCollapsed, navType, headerNavColor, mobileNav, currentTheme, direction }
};

export default connect(mapStateToProps, { toggleCollapsedNav, onMobileNavToggle })(HeaderNav);