import plh from 'assets/img/plh.png';
import { APP_CMCURL,APP_MODE,USER_EMAIL, USER_SESSION, USER_UUID} from 'configs/environment.config';
export const EMAIL =USER_EMAIL;
export const IMAGEPLACEHOLDER = plh
export const IMAGEURL = APP_CMCURL;
export const PROFILEPLACEHOLDER = 'https://joeschmoe.io/api/v1/random'
export const MODE = APP_MODE;
export const CMCURL =APP_CMCURL;
export const UUID = USER_UUID;
export const SESSION =USER_SESSION;
