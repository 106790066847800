import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_BASE_URL } from 'configs/app.config'

export const kycApi = createApi({
    reducerPath: 'kycApi',
    tagTypes: ['get', "update", "add", "kyc1", "kyc2", "kyc3","kyc"],
    baseQuery: fetchBaseQuery({
        baseUrl: API_BASE_URL, prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.token
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }
            return headers
        },
    }),

    endpoints: (builder) => ({

        getGeneralInfo: builder.query({
            query: () => "ms/user/kyc/general-info",
            providesTags: ['kyc1', 'add', 'update', 'get'],
        }),
        updateGeneralInfo: builder.mutation({
            query: (body) => ({
                url: "/ms/user/kyc/general-info",
                method: "PATCH",
                body
            }),
            invalidatesTags: ['kyc1'],
        }),

        getBusinessInfo: builder.query({//
            query: () => "/ms/user/kyc/business-info",
            providesTags: ['kyc2'],
        }),
        updateBusinessInfo: builder.mutation({//
            query: (body) => ({
                url: "ms/user/kyc/business-info",
                method: "PATCH",
                body
            }),
            invalidatesTags: ['kyc2'],
        }),

        getBusinessAddress: builder.query({
            query: () => "ms/user/kyc/address",
            providesTags: ['kyc'],
        }),
        updateBusinessAddress: builder.mutation({
            query: (body) => ({
                url: "ms/user/kyc/address",
                method: "PATCH",
                body
            }),
            invalidatesTags: ['kyc'],

        }),

        getGeneralManager: builder.query({
            query: () => "/ms/user/kyc/general-manager-detail",
            providesTags: ['kyc2'],
        }),

        updateGeneralManager: builder.mutation({
            query: (body) => ({
                url: "/ms/user/kyc/general-manager-detail",
                method: "PATCH",
                body
            }),
            invalidatesTags: ['kyc2'],
        }),

        getBusinessDetail: builder.query({
            query: () => "/ms/user/kyc/business-detail",
            providesTags: ['kyc3'],
        }),
        updateBusinessDetail: builder.mutation({
            query: (body) => ({
                url: "/ms/user/kyc/business-detail",
                method: "PATCH",
                body
            }),
            invalidatesTags: ['kyc3'],
        }),

        getCorporationType: builder.query({
            query: () => "ms/admin/kyc/type-of-incorporation/all",
            invalidatesTags: ['add']
        }),
        getBusinessCategory: builder.query({
            query: () => "ms/admin/kyc/business-category/all",
            invalidatesTags: ['add']
        }),
        getTransactionVolume: builder.query({
            query: () => "ms/admin/kyc/transaction-volume/all",
            invalidatesTags: ['add']
        }),

        getKYCLevel: builder.query({
            query: () => "/ms/user/kyc/level",
            providesTags: ['add', 'get']
        }),
        updateKYCLevel: builder.mutation({
            query: (body) => ({
                url: "/ms/user/kyc/change-kyc",
                method: "PATCH",
                body
            }),
            invalidatesTags: ['add', 'get'],
        }),
        addBankAccount: builder.mutation({
            query: (body) => ({
                url: "/ms/user/kyc/change-kyc",
                method: "PATCH",
                body
            }),
            invalidatesTags: ['add', 'get'],
        }),
        getAllBanks: builder.query({
            query: () => "ms/bank-account",
            providesTags: ['get']
        }),


    }),
})

export const {

    useGetGeneralInfoQuery,
    useUpdateGeneralInfoMutation,
    useGetCorporationTypeQuery,
    useGetBusinessCategoryQuery,
    useGetTransactionVolumeQuery,
    useGetKYCLevelQuery,
    useUpdateKYCLevelMutation,
    useGetBusinessInfoQuery,//
    useUpdateBusinessInfoMutation,//
    useGetBusinessAddressQuery,
    useUpdateBusinessAddressMutation,
    useGetGeneralManagerQuery,
    useUpdateGeneralManagerMutation,
    useGetBusinessDetailQuery,
    useUpdateBusinessDetailMutation,
    useAddBankAccountMutation,
    useGetAllBanksQuery
} = kycApi