import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_BASE_URL } from 'configs/app.config'
import { AUTH_TOKEN } from 'redux/constants/Auth'
import SessionStorageService from 'helper/SessionStorageService'
import { USERDATA } from 'constants/constant'


const logout = () => {
    SessionStorageService.delete(AUTH_TOKEN)
    SessionStorageService.delete(USERDATA)
    window.location.href = '/'
}

const getData=async (url, baseQuery)=> {
    const response = await baseQuery({ url: url, method: 'GET' })
    if (response?.error) {
        if (response?.error?.status === 401 || response?.error?.status === 403) {
            return logout()
        }
       if (response?.error?.status === 500 || response?.error?.status === 501) {
            return logout()
        }
        
    }
    return response
}
export const transactionMng = createApi({
    reducerPath: 'transaction',
    tagTypes: ["get", "update", "delete", "status"],
    baseQuery: fetchBaseQuery({
        baseUrl: API_BASE_URL, prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.token
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }
            return headers
        },
    }),

    endpoints: (builder) => ({
        
        getAllTransaction: builder.query({
            queryFn: async (query, api, extra, baseQuery)=> {
                const url = `ms/transaction/all?limit=${query.limit}&page=${query.page}&startDate=${query.start}&endDate=${query.end}&filters=${query.filters}&sortBy=${query.sortBy}&orderBy=${query.orderBy}`;
               return getData(url, baseQuery)
            }
        }),
        getGraphData: builder.query({
            queryFn: async (query, api, extra, baseQuery) => {
                const url = `/ms/transaction/graph?filters=${query.filter}&startDate=${query.startDate}&endDate=${query.endDate}`;
                    return getData(url, baseQuery)
            }

        }),
        getStatistic: builder.query({
            queryFn: async (query, api, extra, baseQuery) => {
                const url = `ms/transaction/stat?filters=${query.filter}`;
                return getData(url, baseQuery)
            }

        }),
        
        exportData: builder.mutation({
            queryFn: async (query, api, extraOptions, baseQuery) => {
                const result = await baseQuery({
                    url: 'ms/transaction/excel/export',
                    method: 'POST',
                    body: query,
                    responseHandler: ((response) => response.blob())
                })
               
                let hiddenElement = document.createElement('a');
                let url = window.URL || window.webkitURL;
                let blobEXL = url.createObjectURL(result.data);
                hiddenElement.href = blobEXL;
                hiddenElement.target = '_blank';
                hiddenElement.download = `${query.startDate ? query.startDate : "From Start"}--to--${query.endDate ? query.endDate : "End"}-Transaction.xlsx`;
                hiddenElement.click();
                return { data: null }
            }
        }),
        sessionExpiredDate:builder.mutation({
            queryFn: async (query, api, extraOptions, baseQuery) => {
                const result = await baseQuery({
                    url: 'ms/user/checkout-options',
                    method: 'PATCH',
                    body: query,
                })
                return result
            }
        }),
        exportGroupeData: builder.mutation({
            queryFn: async (query, api, extraOptions, baseQuery) => {
                const result = await baseQuery({
                    url: 'ms/transaction/excel/group/export',
                    method: 'POST',
                    body: query,
                    responseHandler: ((response) => response.blob())
                })
              
                let hiddenElement = document.createElement('a');
                let url = window.URL || window.webkitURL;
                let blobEXL = url.createObjectURL(result.data);
                hiddenElement.href = blobEXL;
                hiddenElement.target = '_blank';
                hiddenElement.download = `${query.startDate ? query.startDate : "From Start"}--to--${query.endDate ? query.endDate : "End"}-Transaction.xlsx`;
                hiddenElement.click();
                return { data: null }
            }
        }),
        deleteTestData: builder.mutation({
            query: (query) => ({
                url: 'ms/transaction/clean/test',
                method: 'DELETE'
            })
        })

    }),
})

export const {
    useGetAllTransactionQuery,
    useGetGraphDataQuery,
    useGetStatisticQuery,
    useExportDataMutation,
    useExportGroupeDataMutation,
    useDeleteTestDataMutation
} = transactionMng