import { useState, useEffect } from 'react';

export function useIdle() {
  const [idleTimeout,setIdleTime]=useState(localStorage.getItem("idleTime")||1200)
  const [isIdle, setIdle] = useState(false);

  useEffect(() => {
    setIdleTime(localStorage.getItem("idleTime")||1200)
    let timeoutId;

    const handleUserActivity = () => {
      setIdle(false);
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => setIdle(true), idleTimeout * 1000);
    };

    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keypress', handleUserActivity);

    timeoutId = setTimeout(() => setIdle(true), idleTimeout * 1000);

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keypress', handleUserActivity);
    };
  }, [idleTimeout]);

  return isIdle;
}
