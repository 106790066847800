import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { apikeymng } from '../../services/services.apikey'
import { transactionMng } from '../../services/service.transaction';
import { userService } from '../../services/service.user';
import Auth from '../reducers/reducers.auth';
import Theme from '../reducers/reducers.theme';
import appSilec from 'redux/reducers/reducers.app';
import transactionSlice from 'redux/reducers/reducer.transaction';
import { kycApi } from 'services/service.kyc';
import { bankapi } from 'services/service.bankaccount';
import { onboardingApi } from 'services/service.onbording';

const store = configureStore({
  reducer: {
    theme: Theme,
    auth: Auth,
    app: appSilec,
    filter: transactionSlice,
    [apikeymng.reducerPath]: apikeymng.reducer,
    [transactionMng.reducerPath]: transactionMng.reducer,
    [userService.reducerPath]: userService.reducer,
    [kycApi.reducerPath]: kycApi.reducer,
    [bankapi.reducerPath]: bankapi.reducer,
    [onboardingApi.reducerPath]: onboardingApi.reducer

  },


  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      apikeymng.middleware,
      transactionMng.middleware,
      bankapi.middleware,
      userService.middleware,
      kycApi.middleware,
      onboardingApi.middleware
    ]),
})



setupListeners(store.dispatch)

export default store;



