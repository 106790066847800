import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_BASE_URL } from 'configs/app.config'

export const apikeymng = createApi({
    reducerPath: 'apikeymng',
    tagTypes: ['API', 'API2'],
    baseQuery: fetchBaseQuery({
        baseUrl: API_BASE_URL, prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.token
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }
            return headers
        },
    }),

    endpoints: (builder) => ({

        getAllApiKey: builder.query({
            query: () => "ms/mngr/api/all",
            providesTags: ['API']
        }),

        createApi: builder.mutation({
            query: (data) => ({
                url: `ms/mngr/api/`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['API'],
        }),
        deleteApikey: builder.mutation({
            query: (id) => ({
                url: `ms/mngr/api/${id}`,
                method: 'DELETE',

            }),
            invalidatesTags: ['API'],
        }),
        enableApiKey: builder.mutation({
            query: (id) => ({
                url: `ms/mngr/api/enable/${id}`,
                method: 'PUT',

            }),
            invalidatesTags: ['API'],
        }),

        disableApiKey: builder.mutation({
            query: (id) => ({
                url: `ms/mngr/api/disable/${id}`,
                method: 'PUT',

            }),
            invalidatesTags: ['API'],
        }),
        
        getApiSettings: builder.query({
            query: () => "/ms/user/checkout-options/",
            providesTags: ['API2']
        }),

        enableCleanup: builder.mutation({
            query: (query) => ({
                url: `ms/user/checkout-options/cleanup/on`,
                method: 'PATCH',
                body: query,
            }),
            invalidatesTags: ['API2'],
        }),

        disableCleanup: builder.mutation({
            query: (query) => ({
                url: `ms/user/checkout-options/cleanup/off`,
                method: 'PATCH',
                body: query
            }),
            invalidatesTags: ['API2'],
        }),

        setSessionExpire: builder.mutation({
            query: (query) => ({
                url: `ms/user/checkout-options/`,
                method: 'PATCH',
                body: query,
            }),
            invalidatesTags: ['API2'],
        }),

        

        

    }),
})

export const {
     useGetAllApiKeyQuery,
      useCreateApiMutation, 
      useDeleteApikeyMutation,
       useEnableCleanupMutation,
        useDisableCleanupMutation,
         useGetApiSettingsQuery, 
         useSetSessionExpireMutation ,
         useDisableApiKeyMutation,
         useEnableApiKeyMutation
        
        } = apikeymng