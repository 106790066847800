import {
	AUTH_TOKEN,


	SIGNOUT_SUCCESS,

	SIGNIN_FAIL,
	SIGNIN_REQUEST,
	SIGNIN_SUCCESS,

} from '../constants/Auth';
import SessionStorageService from 'helper/SessionStorageService';
const initState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
	type: 'error',
	token:SessionStorageService.get(AUTH_TOKEN),
	user: null,
	isSendOtp:false
}

const auth = (state = initState, action) => {
	switch (action.type) {

		case SIGNIN_REQUEST:
			return {
				...state,
				loading: true,
				showMessage: false,
			}
		case SIGNIN_SUCCESS:
			return {
				...state,
				token: action.token,
				message: action.message,
				loading: false,
				user: action.payload,
				type: 'success',
				isSendOtp:true,
				showMessage: true,

			}
		case SIGNIN_FAIL:
			return {
				...state,
				message: action.message,
				loading: false,
				redirect: '/',
				type: 'error',
				showMessage: true,
				isSendOtp:false
			}


		case SIGNOUT_SUCCESS:
			return {
				...state,
				token: null,
				redirect: '/',

			}
		default:
			return state
	}


}

export default auth