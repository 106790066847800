class LocalStorageProvider{

    static save(key,data){
        localStorage.setItem(key,data);
    }
    static delete(key){
        localStorage.removeItem(key);
    }
    static get(key){
        localStorage.getItem(key);
    }
}

export default LocalStorageProvider;