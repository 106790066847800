import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_BASE_URL } from 'configs/app.config'

export const bankapi = createApi({
    reducerPath: 'bankapi',
    tagTypes: ["accounts"], 
    baseQuery: fetchBaseQuery({
        baseUrl: API_BASE_URL, prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.token
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }
            return headers
        },
    }),

    endpoints: (builder) => ({
        addBankAccount: builder.mutation({
            query: (body) => ({
                url: "/ms/bank-account",
                method: "POST",
                body
            }),
            invalidatesTags: ["accounts"],
        }),
        deleteBankAccount: builder.mutation({
            query: (id) => ({
                url: `/ms/bank-account/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ["accounts"], 
        }),
        changeDefaultAccount: builder.mutation({
            query: (id) => ({
                url: `/ms/bank-account/default/${id}`,
                method: 'PATCH'
            }),
            invalidatesTags: ["accounts"],
        }),
        getBankList: builder.query({
            query: () => "/ms/bank/all",
        }),
        getBankAccounts: builder.query({
            query: () => `/ms/bank-account`,
            providesTags: ["accounts"]
        }),
    }),
})

export const {
    useAddBankAccountMutation,
    useDeleteBankAccountMutation,
    useChangeDefaultAccountMutation,
    useGetBankListQuery,
    useGetBankAccountsQuery
} = bankapi
