import React, { useEffect } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppLayout from "layouts/app-layout";
import AuthLayout from 'layouts/auth-layout';
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from 'antd';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/app.config'
import useBodyClass from 'hooks/useBodyClass';
import ErrorOne from "./auth-views/errors/error-page-1";
import { IS_APPROVED } from "constants/constant";

function RouteInterceptor({ children, isAuthenticated, isApproved, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated && isApproved ? (
          children
        ) : (
          <>

            <Redirect
              to={{
                pathname: AUTH_PREFIX_PATH,
                state: { from: location }

              }}
            />
          </>
        )
      }
    />
  );
}


export const Views = (props) => {


  const { locale, token, location, direction } = props;
  const currentAppLocale = AppLocale[locale];
  const [isApproved, setApproved] = React.useState(localStorage.getItem(IS_APPROVED));

  useBodyClass(`dir-${direction}`);

  useEffect(() => {
    setApproved(localStorage.getItem(IS_APPROVED))
  }, []);



  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}>
      <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
        <Switch>

          <Route exact path="/" >
            <Redirect to={`${APP_PREFIX_PATH}`} />
          </Route>
          <Route path={AUTH_PREFIX_PATH}>
            <AuthLayout direction={direction} />
          </Route>
          <RouteInterceptor path={`${APP_PREFIX_PATH}`} isApproved={isApproved} isAuthenticated={token}>
            <AppLayout direction={direction} location={location} />
          </RouteInterceptor>
          <Route path={APP_PREFIX_PATH} >
            <AppLayout direction={direction} location={location} />
          </Route>
          
          <Route path="*">
          <ErrorOne />
          </Route>
        </Switch>
      </ConfigProvider>
    </IntlProvider>
  )
}

const mapStateToProps = ({ theme, auth }) => {
  const { locale, direction } = theme;
  const { token } = auth;
  return { locale, token, direction }
};

export default withRouter(connect(mapStateToProps)(Views));