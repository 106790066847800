import {
  HomeOutlined,
  TransactionOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/app.config'

const dashBoardNavTree = [{
  key: 'home',
  path: `${APP_PREFIX_PATH}/home`,
  title: 'home',
  icon: HomeOutlined,
  breadcrumb: false,
  submenu: []
},
{
  key: 'transaction',
  path: `${APP_PREFIX_PATH}/transaction`,
  title: 'transaction',
  icon: TransactionOutlined,
  breadcrumb: false,
  submenu: []
},
{
  key: 'help',
  path: `${APP_PREFIX_PATH}/help`,
  title: 'help',
  icon: QuestionCircleOutlined,
  breadcrumb: false,
  submenu: []
},


]

const navigationConfig = [
  ...dashBoardNavTree
]

export default navigationConfig;
