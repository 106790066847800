import {
  SIGNIN_FAIL,
  SIGNIN_REQUEST,
  SIGNIN_SUCCESS,
  SIGNOUT_SUCCESS,
  AUTH_TOKEN,
} from '../constants/Auth';

import axios from 'axios';
import { API_BASE_URL } from 'configs/app.config';
import { SESSION, UUID } from 'constants/constant.app';
import { IS_APPROVED, USERDATA } from 'constants/constant';
import SessionStorageService from 'helper/SessionStorageService';
import { ONBOARDING_KYC_DATA } from 'constants/appCommon';
import LocalStorageProvider from 'helper/localStorageProvider'
import { ReDirectLocation } from 'helper/redirectProvider';
import { HOME_PATH, ONBOARDING_PATH, PHONE_VERIFICATION_PATH } from 'constants/pathConstant';
import { AuthErrorMessage, AuthSuccessMessage } from 'utils/message/message';


export const authenticatedUser = (data) => async (dispatch) => {
  dispatch({ type: SIGNIN_REQUEST })
  deleteUserData();
  try {


    const res = await axios.post(`${API_BASE_URL}auth/login`,
    data);
    if (res.status === 200) {
      const token = res.data.data.jwttoken;
      const user = res.data.data.user;
      
      await getUserKycLevel(token);
      saveUserData(token, user);
      dispatch({
        type: SIGNIN_SUCCESS,
        token: token,
        message: AuthSuccessMessage.loginSuccess,
      })
      return true;
    }
  } catch (error) {
    const data = error?.response?.data?.data;
    if (data?.jwttoken === '' && data?.user !== null) {
      sendOpt(data?.user?.uuid)
    } else {
      deleteUserData();
    }
    dispatch({ type: SIGNIN_FAIL, message: data?.msg || AuthErrorMessage.serverError })
  }

};




export const getUserKycLevel = async (token) => {
  
  const level = await axios.get(`${API_BASE_URL}ms/user/kyc/level`, {
    headers: {
      "Authorization": `Bearer ${token}`,
    }
  })
 
  if (level.data?.subLevel) {
    LocalStorageProvider.save(IS_APPROVED, true)
  } else {
    LocalStorageProvider.save(IS_APPROVED, false);
  }

}

export const registerUser = (data) => async (dispatch) => {
  dispatch({ type: SIGNIN_REQUEST })
  try {
    const res = await axios.post(`${API_BASE_URL}auth/register`, data);
    if (res.status === 200) {
      dispatch({
        type: SIGNIN_SUCCESS,
        token: res.data.data.jwttoken,
        message: AuthSuccessMessage.signUpSuccess
      })
      sendOpt(res.data.data)

    }
  } catch (error) {
    dispatch({ type: SIGNIN_FAIL, message: error?.response?.data?.msg || AuthErrorMessage.serverError })
    deleteUserData();
  }
}

const sendOpt = async (uuid) => {
  LocalStorageProvider.save(UUID, uuid);
  const verify = await axios.post(`${API_BASE_URL}auth/otp/phone`, { uuid: uuid });
  LocalStorageProvider.save(SESSION, JSON.stringify(verify.data.data));
  ReDirectLocation(PHONE_VERIFICATION_PATH);
}


const saveUserData = (token, userData) => {
  SessionStorageService.set(AUTH_TOKEN, token)
  SessionStorageService.set(USERDATA, userData)
  ReDirectLocation(userData?.arifPayKYCInfo?.subLevel !== null ? HOME_PATH : ONBOARDING_PATH);

}

const deleteUserData = () => {
  SessionStorageService.delete(AUTH_TOKEN)
  SessionStorageService.delete(USERDATA)
  LocalStorageProvider.delete(ONBOARDING_KYC_DATA);
}

export const logoutUser = () => async (dispatch) => {
  dispatch({
    type: SIGNOUT_SUCCESS,
  })
  SessionStorageService.delete(AUTH_TOKEN)
  SessionStorageService.delete(USERDATA)
  LocalStorageProvider.delete(IS_APPROVED);
  LocalStorageProvider.delete(SESSION);
  LocalStorageProvider.delete(UUID);
  LocalStorageProvider.delete(ONBOARDING_KYC_DATA);
  ReDirectLocation("/")

}


