import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE, DIR_LTR } from 'constants/constant.theme';
import { API_ENDPOINT_URL } from './environment.config'

export const APP_NAME = 'ArifPay';
export const API_BASE_URL = API_ENDPOINT_URL;
export const APP_PREFIX_PATH = '/app';
export const AUTH_PREFIX_PATH = '/auth';

const theme = localStorage.getItem('theme') || 'light';
;

export const THEME_CONFIG = {
	navCollapsed: false,
	sideNavTheme: SIDE_NAV_LIGHT,
	locale: 'en',
	navType: NAV_TYPE_SIDE,
	topNavColor: '#3e82f7',
	headerNavColor: '',
	mobileNav: false,
	currentTheme: theme,
	direction: DIR_LTR
};









// 


