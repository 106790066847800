import { Switch } from "antd";
import { onSwitchTheme, onHeaderNavColorChange } from "redux/actions/action.theme";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { connect } from "react-redux";
import { useEffect } from "react";
import {BgColorsOutlined} from '@ant-design/icons';
const SideNav = ({ currentTheme, onSwitchTheme }) => {
  const { switcher, themes } = useThemeSwitcher();

  const toggleTheme = (isChecked) => {
    onHeaderNavColorChange("");
  
    const changedTheme = isChecked ? "dark" : "light";
    localStorage.setItem("theme", changedTheme);
    const theme = localStorage.getItem("theme");
    onSwitchTheme(theme);
    switcher({ theme: themes[theme] });
  };

  useEffect(() => {
     localStorage.setItem("theme", currentTheme);
     const theme = localStorage.getItem("theme");
    onSwitchTheme(theme);
    switcher({ theme: themes[theme] });
  }, [currentTheme, onSwitchTheme, switcher, themes])
  return (
    <div>
  
      <div className="flex gap-5 ">
      <BgColorsOutlined />
<Switch
          onChange={toggleTheme}
          checked={currentTheme === "dark"}
          checkedChildren="🌑" unCheckedChildren="🌞" />
      </div>
   
    </div>
  );
};

const mapStateToProps = ({ theme }) => {
  const { currentTheme } = theme;
  return { currentTheme };
};
const mapDispatchToProps = {
  onSwitchTheme,
};

export default connect(mapStateToProps, mapDispatchToProps)(SideNav);
