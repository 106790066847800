import React from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Views from './views';
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { THEME_CONFIG } from './configs/app.config';
import axios from 'axios';
import { notification } from 'antd';
import Loading from 'components/shared-components/Loading';


function App() {



  const themes = {
    dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
    light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
  };




  if (store.getState().auth.token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${store.getState().auth.token}`
  }
  notification.config({
    placement: 'topRight',
  });




  return (
    <div className="App">



      {themes ? <Provider store={store}>
        {<ThemeSwitcherProvider themeMap={themes} defaultTheme={THEME_CONFIG.currentTheme} insertionPoint="styles-insertion-point">
          <Router>
            <Switch>
              <Route path="/" component={Views} />
            </Switch>
          </Router>
        </ThemeSwitcherProvider>}
      </Provider> : <div className='text-center'>
        <Loading /></div>}
    </div>
  );
}

export default App;
