import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { AUTH_PREFIX_PATH } from 'configs/app.config'

export const AppViews = (props) => {
  const { location } = props
  const { state } = location;
  let loc = "";

  if (state) {
    loc = state.from.pathname
  }


  return (
    <Suspense fallback={<Loading cover="page" />}>
      <Switch>

        <Route path={`${AUTH_PREFIX_PATH}/login`} component={lazy(() => import(`./authentication/login`))} />
        <Route path={`${AUTH_PREFIX_PATH}/ `} component={lazy(() => import(`./authentication/login`))} />
        <Route path={`${AUTH_PREFIX_PATH}/login`} component={lazy(() => import(`./authentication/login`))} />
        <Route path={`${AUTH_PREFIX_PATH}/register`} component={lazy(() => import(`./authentication/register-2`))} />
        <Route path={`${AUTH_PREFIX_PATH}/forgot_password`} component={lazy(() => import(`./authentication/forgot-password`))} />
        <Route path={`${AUTH_PREFIX_PATH}/forgotpassword`} component={lazy(() => import(`./authentication/forgot-password/forget-byphone`))} />
        <Route path={`${AUTH_PREFIX_PATH}/verify-otp`} component={lazy(() => import(`./authentication/verify/verify.reset_passwordby_phone`))} />
        <Route path={`${AUTH_PREFIX_PATH}/reset_password`} component={lazy(() => import(`./authentication/reset_password/resetPassword`))} />
        <Route exact path={`${AUTH_PREFIX_PATH}/email_validation`} component={lazy(() => import(`./authentication/message`))} />
        <Route exact path={`${AUTH_PREFIX_PATH}/deletedAccount`} component={lazy(() => import(`./deletedAccount/deletedAccount`))} />
        <Route exact path={`${AUTH_PREFIX_PATH}/verify/phone`} component={lazy(() => import(`./authentication/verify/verify.phone`))} />
        <Route exact path={`${AUTH_PREFIX_PATH}/verify/email`} component={lazy(() => import(`./authentication/verify/verify.email`))} />
        <Route path={`${AUTH_PREFIX_PATH}/merchant/onboarding/home`} component={lazy(() => import(`./onbording/welcomeScreen/onboarding.welcomeScreen`))} />
        <Route path={`${AUTH_PREFIX_PATH}/merchant/onboarding/apply`} component={lazy(() => import(`./onbording/generalInfo/onboarding.generalInfo`))} />
        <Route path={`${AUTH_PREFIX_PATH}/termsandconditions`} component={lazy(() => import(`./termsAndPrivacy/terms-and-conditions`))} />
        <Route path={`${AUTH_PREFIX_PATH}/privacyPolicy`} component={lazy(() => import(`./termsAndPrivacy/privacy-policy`))} />
        <Redirect from={`${AUTH_PREFIX_PATH}`} to={`${AUTH_PREFIX_PATH}/login?ref=${loc}`} />
      </Switch>
    </Suspense>
  )
}

export default AppViews;

