import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_BASE_URL } from 'configs/app.config'

export const userService = createApi({
    reducerPath: 'userService',
    tagTypes: ['user', 'add', 'update', 'address', 'auth'],
    baseQuery: fetchBaseQuery({
        baseUrl: API_BASE_URL, prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.token
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }
            return headers
        },
    }),

    endpoints: (builder) => ({
        getUserDetail: builder.query({
            query: () => `ms/user/`,
            providesTags: ['user', 'add'],
        }),
        getCompanyDetail: builder.query({
            query: () => `ms/user/profile/company`,
            invalidatesTags: ['user', 'add', 'update'],
        }),

        getAddressDetail: builder.query({
            query: () => `/ms/user/profile/address/`,
            invalidatesTags: ['update'],
        }),

        getAllCountry: builder.query({
            query: () => `https://restcountries.com/v3.1/all`,
            invalidatesTags: ['address'],
        }),

        updateProfile: builder.mutation({
            query: (query) => ({
                url: `ms/user/profile/edit`,
                method: 'PATCH',
                body: query,
            }),
            invalidatesTags: ['user', 'add'],

        }),
        updateFirstTimeLogin: builder.mutation({
            query: (query) => ({
                url: `ms/user/profile/first-time`,
                method: 'PATCH',
                body: query,
            }),
            invalidatesTags: ['user'],

        }),
        updateCompany: builder.mutation({
            query: (query) => ({
                url: `ms/user/profile/company/edit`,
                method: 'PATCH',
                body: query,
            }),
            invalidatesTags: ['user', 'add', 'update'],

        }),
        updateTheme: builder.mutation({
            query: (query) => ({
                url: `ms/user/profile/user-info/edit`,
                method: 'PATCH',
                body: query,
            }),
            invalidatesTags: ['user'],

        }),
        updateAddress: builder.mutation({
            query: (query) => ({
                url: `ms/user/profile/address/edit`,
                method: 'PATCH',
                body: query,
            }),
            invalidatesTags: ['user', 'add', 'update'],

        }),
        forgetPasswordSendEmail: builder.mutation({
            query: (query) => ({
                url: `auth/forgot_password`,
                method: 'POST',
                body: query,
            }),
            invalidatesTags: ['auth'],

        }),
        checkTokenExist: builder.query({
            query: (q) => `auth/check_token/${q}`,
            invalidatesTags: ['auth'],
        }),
        checkEmailExist: builder.query({
            query: (q) => `auth/check-email?email=${q}`,
            invalidatesTags: ['auth'],
        }),

        checkPhoneExist: builder.query({
            query: (q) => `auth/check-phone?phone=${q}`,
            invalidatesTags: ['auth'],
        }),

        resetPassword: builder.mutation({
            query: (query) => ({
                url: `auth/reset_password`,
                method: 'POST',
                body: query,
            }),
            invalidatesTags: ['auth'],

        }),
        reSendPhoneVerification: builder.mutation({
            query: (query) => ({
                url: `/auth/otp/phone`,
                method: 'POST',
                body: query,
            }),
            invalidatesTags: ['auth'],
        }),

        verifyPhone: builder.mutation({
            query: (query) => ({
                url: `/auth/verify/phone`,
                method: 'POST',
                body: query,
            }),
            invalidatesTags: ['auth'],
        }),
        sendOtpToEmail: builder.mutation({
            query: (query) => ({
                url: `ms/user/otp/email`,
                method: 'POST',
                body: query,
            }),
            invalidatesTags: ['auth'],
        }),
        verifyEmail: builder.mutation({
            query: (query) => ({
                url: `/ms/user/verify/email`,
                method: 'POST',
                body: query,
            }),
            invalidatesTags: ['auth'],
        }),
        changePassword: builder.mutation({
            query: (query) => ({
                url: `ms/user/change_password`,
                method: 'POST',
                body: query,
            }),
            invalidatesTags: ['auth'],
        }),
        sendOtpToPhone: builder.mutation({
            query: (query) => ({
                url: `auth/phone_forgot_password`,
                method: 'POST',
                body: query,
            }),
            invalidatesTags: ['auth'],
        }),
        verifyPhoneToResetPassword: builder.mutation({
            query: (query) => ({
                url: `auth/verify/otp`,
                method: 'POST',
                body: query,
            }),
            invalidatesTags: ['auth'],
        }),



    }),
})

export const {
    useUpdateProfileMutation,
    useGetUserDetailQuery,
    useUpdateCompanyMutation,
    useGetCompanyDetailQuery,
    useUpdateAddressMutation,
    useGetAddressDetailQuery,
    useGetAllCountryQuery,
    useUpdateFirstTimeLoginMutation,
    useUpdateThemeMutation,
    useForgetPasswordSendEmailMutation,
    useCheckTokenExistQuery,
    useResetPasswordMutation,
    useCheckEmailExistQuery,
    useCheckPhoneExistQuery,
    useReSendPhoneVerificationMutation,
    useVerifyPhoneMutation,
    useChangePasswordMutation,
    useVerifyEmailMutation,
    useSendOtpToEmailMutation,
    useSendOtpToPhoneMutation,
    useVerifyPhoneToResetPasswordMutation

} = userService