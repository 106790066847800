import { createSlice } from '@reduxjs/toolkit'

const initialState = { isTest: true }

const AppSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    mode(state,action) {
      state.isTest=action.payload
    },
   
  },
})

export const { mode } = AppSlice.actions
export default AppSlice.reducer
