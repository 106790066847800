import React, { lazy, Suspense, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/app.config'
import { useIdle } from "utils/idle";
import { useDispatch } from 'react-redux'
import { logoutUser } from 'redux/actions/action.auth';

const Home = lazy(() => import(`./home`))
const Transaction = lazy(() => import(`./transaction`))
const Profile = lazy(() => import('./setting'));
const KYC = lazy(() => import('./kyc/kyc'));
const Help = lazy(() => import('./help'));

export const AppViews = () => {
  const idle = useIdle();
  const dispatch = useDispatch();

  useEffect(() => {
    if (idle) {
      dispatch(logoutUser())
    }
  }, [idle, dispatch])




  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route path={`${APP_PREFIX_PATH}/home`} component={Home} />
        <Route path={`${APP_PREFIX_PATH}/transaction`} component={Transaction} />
        <Route path={`${APP_PREFIX_PATH}/settings`} component={Profile} />
        <Route path={`${APP_PREFIX_PATH}/kyc`} component={KYC} />

        <Route path={`${APP_PREFIX_PATH}/help`} component={Help} />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);